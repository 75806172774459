@media only screen and (max-width: 480px) {
  .Toastify__toast-body {
    word-break: break-all;
  }
}

@media only screen and (min-width: 480px) {
  .Toastify__toast-container--top-center {
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0 !important;
  }

  .Toastify__toast-container {
    width: auto;
    max-width: 800px;
    min-width: 500px;
  }

  .Toastify__toast {
    padding: 12px;
    border-radius: 3px;
    box-shadow:  0 1px 2px rgb(25 25 25 / 15%), 0 3px 15px rgb(25 25 25 / 10%);
  }
}
