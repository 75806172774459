@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./Roboto-Regular.ttf') format('truetype');
  }

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('./Roboto-Light.ttf') format('truetype');
  }
